import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const CopyLink = ({ link }) => {

  const { t, i18n } = useTranslation();
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      toast.success(t('p_34'));
    }, (err) => {
      console.error('Échec de la copie du lien: ', err);
      toast.error( t('p_36'));
    });
  };

  return (
    <div>
      
    
      
      <div onClick={copyToClipboard} style={{ cursor: 'pointer',}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clipboard-minus-fill text-black" viewBox="0 0 30 30">
          <circle cx="15" cy="15" r="15" fill="white" />
          <g transform="translate(6, 7)">
            <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"/>
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5zM6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1 0-1"/>
          </g>
        </svg>
      </div>
      
    </div>
  );
};

export default CopyLink;
