import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import france from "./Assets/france.jpg";
import usa from "./Assets/usa.jpg";

function Lang() {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState('fr'); // État local pour la langue actuelle, par défaut 'fr'
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng); // Mettre à jour l'état de la langue actuelle
    closeDropdown();
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {currentLang === 'fr' ? (
            <img src={france} className='h-5' alt="Drapeau France" />
          ) : (
            <img src={usa} className='h-5' alt="Drapeau USA" />
          )}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <a
              href="#"
              className="flex px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
              onClick={() => changeLanguage('fr')}
            >
              <img src={france} className='h-5 me-2' alt="Drapeau France" /> {t('p_32')}
            </a>
            <a
              href="#"
              className="flex px-4 py-2 text-sm text-gray-700"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-1"
              onClick={() => changeLanguage('en')}
            >
              <img src={usa} className='h-5 me-2' alt="Drapeau USA" />  {t('p_33')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Lang;
