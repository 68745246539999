import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importez vos fichiers de traduction
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

// Les ressources de traduction
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

// Fonction pour déterminer la langue préférée du navigateur
const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  return language.split('-')[0]; // Retourne 'en' au lieu de 'en-US', par exemple
};

i18n
  .use(initReactI18next) // Passez i18n à react-i18next
  .init({
    resources,
    lng: getBrowserLanguage(), // Langue par défaut basée sur le navigateur
    fallbackLng: 'en', // Langue de secours

    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
