import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
function Countdown({ startDate, endDate }) {
  const { t, i18n } = useTranslation();

  
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      // const startEvent = new Date(startDate);
      const endTime = new Date(endDate);

      // Calcul du temps restant entre la date actuelle et la date de fin
      const totalSeconds = Math.floor((endTime - now) / 1000);

      if (totalSeconds > 0) {
        const daysLeft = Math.floor(totalSeconds / (60 * 60 * 24));
        const hoursLeft = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutesLeft = Math.floor((totalSeconds % (60 * 60)) / 60);
        const secondsLeft = Math.floor(totalSeconds % 60);

        setDays(formatNumber(daysLeft));
        setHours(formatNumber(hoursLeft));
        setMinutes(formatNumber(minutesLeft));
        setSeconds(formatNumber(secondsLeft));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate, endDate]);

  const formatNumber = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const pluralize = (num, label) => {
    return num > 1 ? label + 's' : label;
  };

  return (
    <div className="Animation-option flex md:flex-row flex-col md:space-x-10 md:space-y-0 space-y-10 px-5 px-lg-10 my-20 justify-around items-center">
      <div className="sm:text-5xl text-3xl font-bold normal-case  text-white">
      {t('p_25')} : 
      </div>
      <div className="box-rebours flex  flex-row items-center">
        <div className="box_jour mr-1 border-4 border-white text-red-500 text-center p-2 p-lg-4 w-36 h-32 rounded-md">
          <div id="jour" className="text-6xl font-bold">{days}</div>
          <span id="jour_label" className="text-2xl capitalize">{pluralize(parseInt(days), t('p_29'))}</span>
        </div>
        <p className="text-6xl font-bold text-white point">:</p>
        <div className="box_heure mr-1 ml-1 border-4 border-white text-red-500 text-center p-2 p-lg-4 w-36 h-32 rounded-md">
          <div id="heure" className="text-6xl font-bold">{hours}</div>
          <span id="heure_label" className="text-2xl capitalize">{pluralize(parseInt(hours), t('p_26'))}</span>
        </div>
        <p className="text-6xl font-bold text-white point">:</p>
        <div className="box_minute mr-1 ml-1  border-4 border-white text-red-500 text-center p-2 p-lg-4 w-36 h-32 rounded-md">
          <div id="minute" className="text-6xl font-bold">{minutes}</div>
          <span id="minute_label" className="text-2xl capitalize">{pluralize(parseInt(minutes), t('p_27'))}</span>
        </div>
        <p className="text-6xl font-bold text-white point">:</p>
        <div className="box_seconde mr-1 ml-1  border-4 border-white text-red-500 text-center p-2 p-lg-4 w-36 h-32 rounded-md">
          <div id="seconde" className="text-6xl font-bold">{seconds}</div>
          <span id="seconde_label" className="text-2xl capitalize">{pluralize(parseInt(seconds), t('p_28'))}</span>
        </div>
      </div>
    </div>
  );
}

export default Countdown;
